
    export default {
        data() {
            return {
                pathInclude(s){
                    return this.$route.path.includes(s);
                },
                loading: false,
                field : {
                    name: 'email',
                    placeholder: this.$__('newsletterEmailPlaceholder'),
                    type: 'email',
                    error: false,
                    required: true,
                    value : null,
                    class : 'white'
                }
            }
        },
        computed:{
            profile(){
                return this.$store.state.user.profile;
            },
            alredySubscribed(){
                return this.$store.state.newsletter.alreadySubscribed
            }
        },
        created(){
            if(this.profile){
                this.$store.dispatch('newsletter/checkAlreadySubscribed', this.profile.email)
            }
        },
        methods : {
            registerNewsletter(){
                this.$emit('evalField');
                if(!this.field.error){
                    this.loading = true;
                    this.$axios.post('/newsletter/subscribe-to-newsletter', {recipient: this.field.value}).then(data=> {
                        this.$toastSuccess('successfullySubscribedToNewsletter')
                        this.loading = false;
                        this.field.value = null;
                        this.$nextTick(() => {
                            this.field.error = false;
                        })
                    }).catch(err=> {
                        this.loading = false;
                        return false;
                    })
                }
            }
        }
    }
